<template>
	<div id="homeView">
		<CommonHeader></CommonHeader>
		<HomeCenter></HomeCenter>
		<CommonBottom></CommonBottom>
	</div>
</template>

<script>
	import CommonHeader from '../../components/CommonHeader.vue'
	import HomeCenter from '../../components/HomeCenter.vue'
	import CommonBottom from '../../components/CommonBottom.vue'
	
	export default {
		name: "Home",
		components: {
			CommonHeader,
			HomeCenter,
			CommonBottom
		},
		
		created() {
			
		},
		
		mounted() {
			// 判断当前设备为手机端
			if(screen.width < 1200) {
				// 移动端
				window.location.href = "http://hlhiot.com/h5/#/pages/index/index"
				return;
			}
		}
		
	};
</script>

<style>
	
	#homeView {
		
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		min-width: 1200px;
		min-height: 100%;

		text-align: center;
		padding-bottom: 160px;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		position: relative;

	}
</style>
